/**
 * login模块接口列表
 */

 import request from "@/utils/request"; // 导入http中创建的axios实例
 import qs from "qs"; // 根据需求是否导入qs模块
 
 const task = {
   // 任务列表
   menuList(params) {
     return request({
       url: "Admin_Task/menuList",
       method: "post",
    //    data: qs.stringify(params),
       data: params,
     });
   },
   // 任务列表
   collectList(params) {
    return request({
      url: "Admin_Task/collectList",
      method: "post",
   //    data: qs.stringify(params),
      data: params,
    });
  },
  taskDetails(params) {
    return request({
      url: "Admin_Task/taskDetails",
      method: "post",
   //    data: qs.stringify(params),
      data: params,
    });
  },
  excelDemo(params) {
    return request({
      url: "Admin_Task/excelDemo",
      method: "post",
   //    data: qs.stringify(params),
      data: params,
    });
  },
  excelUpload(params) {
    return request({
      url: "Admin_Task/excelUpload",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // data: qs.stringify(params),
      data: params,
    });
  },
  idFromList(params) {
    return request({
      url: "Admin_Task/idFromList",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // data: qs.stringify(params),
      data: params,
    });
  },
  delIdFrom(params) {
    return request({
      url: "Admin_Task/delIdFrom",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // data: qs.stringify(params),
      data: params,
    });
  },
  editIdFrom(params) {
    return request({
      url: "Admin_Task/editIdFrom",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // data: qs.stringify(params),
      data: params,
    });
  },
  rejectCollectLog(params) {
    return request({
      url: "Admin_Task/rejectCollectLog",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // data: qs.stringify(params),
      data: params,
    });
  },
  delOrder(params) {
    return request({
      url: "Admin_Task/delOrder",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // data: qs.stringify(params),
      data: params,
    });
  },
  downTaskList(params) {
    return request({
      url: "Admin_Task/downTaskList",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // data: qs.stringify(params),
      data: params,
    });
  },
  makeDownTask(params) {
    return request({
      url: "Admin_Task/makeDownTask",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // data: qs.stringify(params),
      data: params,
    });
  },
 };
 
 export default task;
 
// const TokenKey = "token";
const UserInfoKey = "UserInfo";

// export function getToken() {
//   return window.localStorage.getStorageSync(TokenKey);
// }

// export function setToken(token) {
//   return window.localStorage.setStorageSync(TokenKey, token);
// }

// export function removeToken() {
//   return window.localStorage.removeStorageSync(TokenKey);
// }

export function getUserInfo() {
  return localStorage.getItem(UserInfoKey);
}

export function setUserInfo(userInfo) {
  return localStorage.setItem(UserInfoKey, userInfo);
}

export function removeUserInfo() {
  return localStorage.removeItem(UserInfoKey);
}

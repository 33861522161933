import qs from 'qs';
import axios from 'axios';
import { Loading, Message } from "element-ui";
import { baseUrl } from "@/config";

const service = axios.create({
  timeout: 120000,
});

// axios请求拦截
service.interceptors.request.use(
	config => {
		// token处理
		const token = window.sessionStorage.getItem("token");
    if (token) {
      config.headers["accessToken"] = token;
    }
		// console.log("request config", config)
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

// axios响应拦截
service.interceptors.response.use(
	(config) => {
		// console.log("response config", config)

		let judgeList = [config.data.code !== "000000", config.config.responseType !== "blob", document.getElementsByClassName('el-message').length === 0];
		if (judgeList.every(item => item)) {
			try {
				Message.warning(config.data.mesg || config.data.msg || config.data.data || '网络繁忙，请稍后重试');
			} catch (error) {
				console.warn('element-ui Not Found');
			}
		};
		return config;
	},
	error => { // error 
		let msgText = 'Internal Server Error';
		if (JSON.stringify(error).includes('404')) {
			msgText = 'API Not Found';
		};
		if (JSON.stringify(error).includes('401')) {
			msgText = '无访问权限, 请联系管理员！';
		};

		if (document.getElementsByClassName('el-message').length === 0) {
			try {
				Message.error(msgText);
			} catch (error) {
				console.warn('element-ui Not Found');
			};
		};

		return Promise.reject(error); // 抛出异常
	}
);

// loading加载次数
let loadingCount = 0;

/**
 * 请求
 * @param {String} url 			请求地址
 * @param {Any} data 			请求数据
 * @param {String} method 		请求方法
 * @param {Boolean} loading 	是否添加loading
 * @param {Object} headers 		headers
 * @param {Object} param 		其他设置
 */
const http = ({
	url,
	data = {},
	method = 'GET',
	loading = true,
	headers = {},
	...param
} = {}) => {
	if (!url) {
		throw new Error('URL Cannot Be Empty !');
	};
	return new Promise((resolve, reject) => {
		// 加载设置
		let loadingInstance = "";
		if (loading) {
			try {
				loadingInstance = Loading.service({
					lock: true,
					text: '加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					fullscreen: true
				});
				loadingInstance && loadingCount++;
			} catch (error) {
				console.warn('element-ui Not Found');
			}
		};


		/**
		 * 请求头Content-Type
		 * multipart/form-data
		 * application/x-www-form-urlencoded
		 * application/json
		 */
		switch (headers['Content-Type']) {
			case 'application/x-www-form-urlencoded': // x-www-form-urlencoded
				data = qs.stringify(data);
				break;
			case 'multipart/form-data': // form-data
				let temp = new FormData();
				for (let it in data) {
					temp.append(it, data[it]);
				};
				data = temp;
				break;
			default:
				break;
		}
		// 兼容API链接
		let requestUrl = '';
		if (["https://", "http://"].some(item => url.includes(item))) {
			requestUrl = url;
		} else {
			requestUrl = baseUrl + (url[0] !== "/" ? "/" + url : url);
		};

		// 请求相关
		let requestParams = {
			url: requestUrl,
			method: method,
			[method ? method.toUpperCase() === 'GET' ? 'params' : 'data' : 'data']: data,
			headers: headers,
			...param
		};

		service(requestParams)
			.then(res => { // 成功
				if (res.data.code !== "000000" && res.config.responseType !== "blob") {
					reject(res);
				} else {
					resolve(res.data);
				}
			}).catch(err => { // 失败
				reject(err);
			}).finally(() => {
				// 关闭loading
				if (loadingInstance) {
					setTimeout(() => {
						loadingCount > 0 && loadingCount--;
          				loadingCount === 0 && loadingInstance.close();
					}, 500);
				};
			});
	});
};

export default http;
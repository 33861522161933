/**
 * home模块接口列表
 */

import request from "@/utils/request"; // 导入http中创建的axios实例
import requests from "@/utils/requests"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块

const home = {
    // 获取左侧菜单列表
    menus() {
        return request({
            url: "menus",
            method: "get",
        });
    },
    //微信支付
    wxPayQrcode(params) {
        return request({
            url: "wxPay/wxPayQrcode",
            method: "post",
            data: params,
        });
    },
    // 获取首页通知
    HomeNoctice(params) {
        return request({
            url: "advice/list",
            method: "post",
            data: params,
        });
    },
    HomeNocticetop() {
        return request({
            url: "advice/listTop10",
            method: "get",
        });
    },
    //修改已读
    HomeNocticechange(id) {
        return request({
            url: "advice/change/" + id,
            method: "post",
        });
    },
    // 获取首页列表
    homeMenuList(params) {
        return request({
            url: "resume/list",
            method: "post",
            data: params,
        });
    },
    // 获取首页筛选列表
    filterList() {
        return request({
            url: "dictionary/",
            method: "get",
        });
    },
    // 获取首页详情数据
    // HomeDetail(id) {
    //   return request({
    //     url: `resume/${id}`,
    //     method: "get",
    //   });
    // },
    HomeDetail(id) {
        return request({
            url: `resume/id/${id}`,
            method: "get",
        });
    },
    // 获取职位列表
    JobManageList(params) {
        return request({
            url: "position/list",
            method: "post",
            data: params,
        });
    },
    // 新增职位
    JobManageAdd(params) {
        return request({
            url: "position/",
            method: "post",
            data: params,
        });
    },
    // 职位详情
    JobManagealter(params) {
        return request({
            url: "position/" + params,
            method: "get",
        });
    },
    // 修改职位
    JobManageup(params) {
        return request({
            url: "position/",
            method: "put",
            data: params,
        });
    },
    //通过发布的职位获取投递的简历

    orderByDeliveriesCount() {
        return request({
            url: `position/orderByDeliveriesCount`,
            method: "get",
        });
    },
    //导出简历

    exportByDeliver(id) {
        return request({
            url: `deliver/exportByDeliver/${id}`,
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            responseType:   'blob'
        });
    },
    // 修改职位status
    JobManageupstatus(id, status) {
        return request({
            url: `position/${id}/${status}`,
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },
    JobManagedetele(id, params) {
        return request({
            url: "position/" + id,
            method: "delete",
            data: params,
        });
    },
    // 获取消息列表
    MessageList(params) {
        return request({
            url: "MessageList",
            method: "get",
            params: params,
        });
    },
    // 获取角色管理列表
    RolesList(params) {
        return request({
            url: "role/list",
            method: "post",
            data: params,
        });
    },
    //新增角色
    // 获取用户管理列表
    userList(params) {
        return request({
            url: "user/list",
            method: "post",
            data: params,
        });
    },
    //编辑接口获取menuids
    editUserListId(id) {
        return request({
            url: "role/" + id,
            method: "get",
        });
    },
    MenuPermission(params) {
        return request({
            url: "menu/listTree",
            method: "post",
            data: params,
        });
    },
    //编辑角色
    editUserList(params) {
        return request({
            url: "role/",
            method: "put",
            data: params,
        });
    },
    //新增用户
    addUser(params) {
        return request({
            url: "user/",
            method: "put",
            data: params,
        });
    },
    //获取企业信息
    Enterprises() {
        return request({
            url: "enterprises/",
            method: "get",
        });
    },
    //修改企业信息
    Enterprisespost(params) {
        return request({
            url: "enterprises/",
            method: "post",
            data: params,
        });
    },
    //申请企业认证
    Enterprisespost(params) {
        return request({
            url: "enterprises/",
            method: "post",
            data: params,
        });
    },
    //申请企业认证被拒绝
    getHistoryByReject() {
        return request({
            url: "enterprises/getHistoryByReject",
            method: "get",
        });
    },
    //继续申请企业认证
    Enterprisespostment(params) {
        return request({
            url: "enterprises/authentication",
            method: "post",
            data: params,
        });
    },
    //获取企业向平台认证费用
    EntersystemSetting() {
        return request({
            url: "systemSetting/",
            method: "get",
        });
    },

    // 获取申请认证列表
    ApplyList(params) {
        return request({
            url: "enterprisesAuthen/list",
            method: "post",
            data: params,
        });
    },
    // 删除申请认证列表
    deleteApplyList(ids) {
        return request({
            url: `enterprisesAuthen/delete/${ids}`,
            method: "post",
            // data: params,
            // headers: {
            //   "Content-Type": "application/x-www-form-urlencoded",
            // },
        });
    },
    // 导出申请认证列表
    exportApplyList(params) {
        let arr = params.split(',');
        let ids = "";
        arr.forEach((ele, index) => {
            if (index == 0) {
                ids = "ids=" + ele;
            } else {
                ids += "&ids=" + ele;
            }
        });
        // return requests({
        //   url: `enterprisesAuthen/export?` + ids,
        //   method: 'POST',
        //   responseType: 'blob'
        // });
        return requests({  url:   `enterprisesAuthen/export?${ids}`,  data: {},  method:   'POST',  responseType:   'blob'  })
    },
    // 获取申请认证列表的简历
    ApplyListmemberId(params) {
        return request({
            url: "enterprisesAuthen/" + params,
            method: "get",
        });
    },
    // 获取审核申请认证
    prisesApplyList(params) {
        return request({
            url: "enterprisesAuthen/update",
            method: "post",
            data: qs.stringify(params),
            // headers: {
            //   "Content-Type": "application/x-www-form-urlencoded",
            // },
        });
    },
    // 获取向平台申请认证资格
    ApplyListAuthen(params) {
        return request({
            url: "enterprisesAuthen/applyForCertification",
            method: "post",
            data: params,
        });
    },
    // 获取专家认证列表
    authenApplyList(params) {
        return request({
            url: "authen/" + params,
            method: "get",
        });
    },
    // 企业修改认证费用
    updatenterprisesAuthen(params) {
        return request({
            url: "enterprisesAuthen/updateFee",
            method: "post",
            data: qs.stringify(params),
        });
    },
    // 获取面试模板设置列表
    InterviewSettingList(params) {
        return request({
            url: "template/list",
            method: "post",
            data: params,
        });
    },
    // 添加面试模板
    addInterviewSettingList(params) {
        return request({
            url: "template/",
            method: "put",
            data: params,
        });
    },
    // 修改面试模板
    updataInterviewSettingList(params) {
        return request({
            url: "template/update",
            method: "post",
            data: params,
        });
    },

    // 删除面试模板
    deleteInterviewSettingList(id) {
        return request({
            url: "template/" + id,
            method: "delete",
        });
    },

    // 邀约面试
    inviteInterview(params) {
        return request({
            url: "deliver/interview",
            method: "post",
            data: params,
        });
    },
    // 首页简历邀约面试
    resumeInterview(resumeId, params) {
        return request({
            url: "resume/interview/" + resumeId,
            method: "post",
            data: params,
        });
    },
    // 已查看简历
    updataInterview(id, status) {
        return request({
            url: `deliver/updateStatus/${id}/${status}`,
            method: "post",
        });
    },


    // 取消面试
    canclInterview(params) {
        return request({
            url: "deliver/cancelInterview",
            method: "post",
            data: qs.stringify(params),
        });
    },

    //获取我的简历
    DeliveredList(params) {
        return request({
            url: "deliver/deliveredList",
            method: "post",
            data: params,
        });
    },
    //获取我的id详情简历
    DeliveredId(id, params) {
        return request({
            url: `deliver/${id}`,
            method: "get",
            params: params,
        });
    },
    //反馈面试情况
    updataAbnormalStatus(id) {
        return request({
            url: `deliver/updateAbnormalStatus/${id}`,
            method: "post",
        });
    },
    //获取我的id认证情况
    DeliveredIdtype(memberId) {
        return request({
            url: `authen/type/${memberId}`,
            method: "get",
        });
    },
    //获取我的id认证列表
    DeliveredIdmember(memberId, type) {
        return request({
            url: `authen/history/${memberId}/` + type + "?type=" + type,
            method: "get",
        });
    },
    //删除简历
    deleteDeliveredId(params) {
        return request({
            url: `deliver/`,
            method: "DELETE",
            data: params,
        });
    },
    //导出简历
    exportDeliveredId(params) {
        return request({
            url: `deliver/export`,
            method: "post",
            data: params,
            responseType:   'blob'
        });
    },
    // 获取佣金列表
    CommissionList(params) {
        return request({
            url: "order/commissionList",
            method: "post",
            data: params,
        });
    },
    // 获取企业佣金订单列表
    EnterprisesAuthenList(params) {
        return request({
            url: "order/enterprisesAuthenList",
            method: "post",
            data: params,
        });
    },
    // 获取提现记录订单列表
    WithdrawalRecordList(params) {
        return request({
            url: "order/withdrawalRecordList",
            method: "post",
            data: params,
        });
    },
    // 获取可提现总额
    checktotalWithdrawal() {
        return request({
            url: `order/totalWithdrawal`,
            method: "get",
        });
    },
    //提现总额
    depositwithdrawalRecord(params) {
        return request({
            url: `withdrawalRecord/withdraw`,
            method: "post",
            data: params,
        });
    },
    // 获取最低提现佣金
    getMinimumWithdrawalAmount() {
        return request({
            url: `withdrawalRecord/getMinimumWithdrawalAmount`,
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },
    // 获取提现佣金列表
    InvoiceList(params) {
        return request({
            url: "invoice/list",
            method: "post",
            data: params,
        });
    },

    // 获取提现佣金列表详情
    checkInvoiceList(id) {
        return request({
            url: `invoice/${id}`,
            method: "get",
        });
    },
    // 开发票
    iceList(id) {
        return request({
            url: `invoice/invoicing`,
            method: "post",
            data: id,
        });
    },
    // 查询用户状态
    QueryUser(id) {
        return request({
            url: `user/${id}`,
            method: "get",
        });
    },
    // 编辑用户
    editUser(params) {
        return request({
            url: `user/update`,
            method: "post",
            data: params,
        });
    },
    // 删除用户
    deleteUser(userId, params) {
        return request({
            url: `user/${userId}`,
            method: "delete",
            params: params,
        });
    },
    //修改用户密码
    deletePasswork(params) {
        return request({
            url: `user/changePassword`,
            method: "put",
            data: params,
        });
    },

    // 添加角色
    addRole(params) {
        return request({
            url: "role/",
            method: "post",
            data: params,
        });
    },
    // 编辑用户
    editRole(userId, params) {
        return request({
            url: `roles/${userId}`,
            method: "put",
            data: qs.stringify(params),
        });
    },
    // 删除用户
    deleteRole(roleId, params) {
        return request({
            url: `role/${roleId}`,
            method: "delete",
            params: params,
        });
    },
    //获取行业职业id

    occupationList(params) {
        return request({
            url: `occupation/list`,
            method: "post",
            data: params,
        });
    },
    //获取行业

    occupationListlistIndustry(params) {
        return request({
            url: `occupation/listIndustry`,
            method: "post",
            data: params,
        });
    },
    //获取字典

    gaindictionary() {
        return request({
            url: `dictionary/`,
            method: "get",
        });
    },
};

export default home;
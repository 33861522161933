import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { MessageBox } from "element-ui";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import ("@/views/Login/Login.vue"),
    },
    {
        path: "/loginSuccess",
        name: "loginSuccess",
        component: () =>
            import ("@/views/Login/LoginSuccess.vue"),
    },
    {
        path: "/main",
        name: "main",
        component: () =>
            import ("../views/Main.vue"),
        redirect: "/home",
        meta: { title: "首页-采集列表" },
        children: [
            {
                path: "/home",
                meta: { title: "首页-采集列表" },
                component: () =>
                    import ("../views/Home/Home.vue"),
            },
            {
                path: "/home_info",
                meta: { title: "首页-采集信息" },
                component: () =>
                    import ("../views/Home/HomeInfo.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    const token = window.sessionStorage.getItem("token");

    if (to.path === "/login" && token) {
        return next("/home");
    }

    if (to.path === "/login" || to.path === "/loginSuccess") {
        return next();
    }

    next();
});

export default router;
import axios from "axios";
// import store from "@/store";
// 根据环境不同引入不同api地址
import { baseUrl } from "@/config";
import router from "../router";

import { Loading, Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

// true 开发 false 生产
let DEBUG = false;

const service = axios.create({
    // baseURL: "", //设置为空时就可以请求Mock数据了
    baseURL: 'https://yunphoto.hzven.com/index.php/',
    // baseURL: baseUrl,
    timeout: 1000*120,
});
let loading = null;
// request拦截器 request interceptor
service.interceptors.request.use(
    (config) => {
        // 不传递默认开启loading
        if (!config.hideloading) {
          // loading
          loading = Loading.service({
            lock: true,
            text: "加载中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
        }
        NProgress.start();
        const token = window.sessionStorage.getItem("token");
        if (token) {
            config.headers["token"] = token;
        }
        // console.log(config, "config");
        // if (config.url.indexOf("/api") == -1) {
        //   config.baseURL = "";
        // } else {
        // config.baseURL = baseUrl + '/api/' + config.url;
        // }
        if (DEBUG) {
            config.baseURL = '/api/';
        }
        return config;
    },
    (error) => {
        // console.log('aaaaaaaaaaaaa', error);
        return Promise.reject(error);
    }
);
// respone拦截器
service.interceptors.response.use(
    (response) => {
        if (loading) {
          loading.close();
        }
        NProgress.done();
        const res = response.data;
        // console.log('==', response);
        if (response.config.responseType == 'blob') {
            return Promise.resolve(res);
        }
        if (res.ret && res.ret !== 200) {
            // 登录超时,重新登录
            if (res.ret === 401 && router.history.current.fullPath != '/login') {
                window.sessionStorage.removeItem("token");
                // store.dispatch("FedLogOut").then(() => {
                //   location.reload();
                // });
                window.location.href = window.location.origin+'/login'
            }
            Message({
                message: res.msg,
                type: "error",
            });
            return Promise.reject(res || "error");
        } else {
            return Promise.resolve(res);
        }
    },
    (error) => {
        if (loading) {
            loading.close();
        }
        console.log("err" + error);
        return Promise.reject(error);
    }
);

export default service;
import login from "./login.js"; // 登录请求
import home from "./home.js"; // 首页请求
import task from "./task.js"; // 首页请求

const api = {
  login: { ...login }, // 登录请求
  home: { ...home }, // 首页请求
  task: { ...task } // 首页请求
};

export default api;

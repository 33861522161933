import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 按需导入
import "./plugins/element.js";
// 导入全局样式表
import "./assets/css/global.css";
import api from "./utils/api/index";
// Mock
// import "./mock/index";
import MescrollVue from "mescroll.js/mescroll.vue";

import './utils/flexiblePC.js'


// 文本截断
import VClamp from "vue-clamp";
import { VueJsonp } from 'vue-jsonp';
Vue.use(VueJsonp)
Vue.directive("has", {
    inserted: function(el, bing) {
        // console.log(store.state.menu);
        for (let index = 0; index < store.state.menu.length; index++) {
            if (store.state.menu[index].menuId == bing.value) {
                // console.log(store.state.menu[index]);
                if (store.state.menu[index].flag == 0) {
                    // console.log(bing.value);
                    el.parentNode.removeChild(el);
                    return;
                }
                return;
            }
        }
    },
});

Vue.component("mescroll-vue", MescrollVue);
// 截取
Vue.component("v-clamp", VClamp);
// api
Vue.prototype.$api = api;

Vue.config.productionTip = false;
// console.log(store.state);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

// window.sessionStorage.setItem('token','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJqd3RfYVdlbiIsImV4cCI6MTY1NjEyODMxMSwic3ViIjoiWUgiLCJhdWQiOiJldmVyeSIsIm5iZiI6MTY1MzUzNjMxMSwiaWF0IjoxNjUzNTM2MzExLCJqdGkiOjEwMDAxLCJ1aWQiOjE3fQ.trTX81DAlrX6p1mul-Yimh8RPo08yOxkrAZkkKSWd6I')
// window.sessionStorage.setItem('user_info','{"org_id":2,"org_info":{"id":2,"name":"测试教育","brief_name":"测试","logo_avatar":"https://mx-yun-photo.oss-cn-shenzhen.aliyuncs.com/uploads/images/20220512/f52810855558287b4590da9d4c470522.png","introduction":"这是机构简介1","org_images":"https://mx-yun-photo.oss-cn-shenzhen.aliyuncs.com/uploads/images/20220512/90cc0a6e1034461f940cfd41bd488e03.png","createtime":null,"updatetime":null,"deletetime":null},"type":"boss_user","nickname":"阿文","avatar":"https://mx-yun-photo.oss-cn-shenzhen.aliyuncs.com/uploads/user/avatar/20220526/7414a200d94407513c9316c9eb585a4a.jpg","id":17}')
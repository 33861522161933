<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app"
}
</script>

<style lang="less">
.flex {
  display: flex;
}

.f_a_center {
  align-items: center;
}

.f_j_center {
  justify-content: center;
}

.f_c {
  flex-direction: column !important;
}

.f_j_start {
  justify-content: flex-start;
}

.f_j_between {
  justify-content: space-between;
}

.f_g_1 {
  flex: 1;
}

.f_g_0 {
  flex: 0;
}
</style>

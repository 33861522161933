/**
 * login模块接口列表
 */

import request from "@/utils/request"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块

const login = {
  // 登录
  pcLogin(params) {
    return request({
      url: "Admin_Login/authLogin",
      method: "post",
      // data: qs.stringify(params),
      data: params,
    });
  }
};

export default login;

import Vue from "vue";
import Vuex from "vuex";
import { getUserInfo, setUserInfo } from "../utils/auth";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      nickname: getUserInfo() ? getUserInfo().nickname : "",
      mainPic: getUserInfo() ? getUserInfo().mainPic : "",
      memberId: getUserInfo() ? getUserInfo().memberId : "",
    },
    menu: [],
    menuLeft: [],
    roleIds: [],
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.user.nickname = userInfo.nickname;
      state.user.mainPic = userInfo.mainPic;
      state.user.memberId = userInfo.memberId;
      setUserInfo(userInfo);
    },
    updatamenu(state, menus) {
      state.menu = menus;
    },
    updatamenuLeft(state, menus) {
      state.menuLeft = menus;
    },
    uproleIds(state, menus) {
      state.roleIds = menus;
    },
  },
  getters: {},
  actions: {},
  plugins: [createPersistedState()],
});
